<template>
  <el-card>
    <form ref="form">
      <file-uploader ref="file"
        accept=".xlsx"
        btnName="上传excel"
        :multiple="false"
        @change="fileChange"
        :showPreview="false"
        :showFileHint="false">
      </file-uploader>
    </form>
    <!-- <el-button @click="reset"> 重置上传文件</el-button> -->
    <el-dialog :visible.sync="uploadDialog" title="上传销售" :close-on-click-modal="false" @close="cancelUpload">
      <div>
        <div>
          {{uploadDistributor.name}}
        </div>
        <span v-if="notFindList.length > 0">没有对应的款号提醒</span>
        <div v-for="(item, index) in notFindList" :Key="index">
          <span>第{{item.row}}条数据</span>
          <span>{{item.kh}}</span>
          <span>{{item.color}}</span>
          <span>{{item.size}}</span>
        </div>
      </div>
      <table class="table bordered">
        <thead>
          <tr>
            <th>#</th>
            <th>编号</th>
            <th>款号</th>
            <th>颜色</th>
            <th>日期</th>
            <th v-for="item in uploadSizes" :key="item">{{item}}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item , index) in uploadList" :key="index">
            <td>{{index + 1}}</td>
            <td>{{item.code.substr(0, 8)}}</td>
            <td>{{item.kh}}</td>
            <td>{{item.color}}</td>
            <td>{{item.occur_date}}</td>
            <td v-for="size in uploadSizes" :key="size">{{item[size]}}</td>
          </tr>
        </tbody>
      </table>
      <!-- <el-form :model="uploadObj" label-width="100px">
      </el-form> -->
      <div slot="footer" class="dialog-footer">
        <el-button @click="cancelUpload" icon="fa fa-fw fa-times"> 取 消</el-button>
        <el-button type="primary" @click="save" :disabled="waiting" icon="fa fa-fw fa-floppy-o"> 保存</el-button>
      </div>
    </el-dialog>
  </el-card>
</template>

<script>
import * as xlsx from 'xlsx';
import FileUploader from '@/components/FileUpload';

export default {
  components: {
    FileUploader,
  },
  data() {
    return {
      waiting: false,
      loading: false,
      data: {},
      khMaps: null,
      allSize: ['F', 'XS', 'S', 'M', 'L', 'XL', 'XXL'],
      uploadSizes: [],
      addArr: [],
      distributors: [],
      notFindList: [],
      uploadDialog: false,
      uploadDistributor: {}, // 上传文件第一行文字匹配的分销商
      files: [],
    };
  },
  created() {
    this.getSKUs();
    this.getDistributors();
  },
  computed: {
    uploadList() {
      const r = [];
      this.addArr.forEach((item) => {
        let lastOne = null;
        if (r.length > 0) {
          lastOne = r[r.length - 1];
        }
        if (lastOne && lastOne.code.substr(0, 8) === item.code.substr(0, 8)) {
          lastOne[item.size] = item.num;
          this.$set(lastOne, item.size, item.num);
        } else {
          const obj = {
            code: item.code,
            kh: item.kh,
            color: item.color,
            occur_date: item.occur_date,
          };
          obj[item.size] = item.num;
          r.push(obj);
        }
      });
      return r;
    },
  },
  methods: {
    getSKUs() {
      this.axios.get('allkhclothes').then((r) => {
        const list = r.data;
        this.khMaps = list;
      });
    },
    getDistributors() {
      const params = { pageSize: 100, page: 1 };
      this.$http.get('distributor', { params }).then((r) => {
        this.distributors = r.data.data;
      });
    },
    fileChange(files) {
      if (files.length > 0) {
        this.files = [];
        Array.prototype.slice.call(files).forEach((file) => {
          this.files.push(file);
        });
      } else {
        this.files = [];
      }
      if (!this.khMaps) {
        setTimeout(() => {
          this.this.fileChange(files);
        }, 3000);
      } else {
        this.toUpload();
      }
    },
    toUpload() {
      const fileReader = new FileReader();
      fileReader.onload = (ev) => {
        const data = ev.target.result;
        const wb = xlsx.read(data, { type: 'binary' });
        const sheetName = wb.SheetNames[0];
        const list = xlsx.utils.sheet_to_json(wb.Sheets[sheetName], { range: 0, header: 'A' });
        let row = 0;
        const addArr = [];
        let sizeRow = null;
        const colMap = {};
        const errs = [];
        const sizes = [];
        list.forEach((item) => {
          row++;
          const keys = Object.keys(item);
          if (row < 4) {
            keys.forEach((col) => {
              const itemVal = item[col]; // excel 每格内容
              // 第一行
              if (row === 1) {
                this.distributors.forEach((distributor) => {
                  if (distributor.name.indexOf(itemVal) !== -1) {
                    this.uploadDistributor = distributor;
                  }
                });
              }
              if (this.allSize.indexOf(itemVal) > 1) {
                colMap[itemVal] = col;
                sizeRow = row;
                sizes.push(itemVal);
              }
              if (itemVal === '售出日期') {
                colMap.occur_date = col;
              }
              if (itemVal === '款号') {
                colMap.kh = col;
              }
              if (itemVal === '颜色') {
                colMap.color = col;
              }
              if (!colMap.sizeCol && String(itemVal).indexOf('码') !== -1) {
                colMap.sizeCol = col;
                sizeRow = row + 1;
              }
            });
          }
          // records
          if (sizeRow && row > sizeRow && colMap.kh && item[colMap.kh]) {
            const kh = item[colMap.kh];
            const occurDate = item[colMap.occur_date];
            const color = item[colMap.color];
            if (kh && color) {
              const obj = {
                kh,
                color,
                occur_date: this.$utils.excelDate(occurDate, 'yyyy-MM-dd'),
              };
              sizes.forEach((size) => {
                if (item[colMap[size]]) {
                  obj[size] = item[colMap[size]];
                }
              });
              const colorMaps = this.khMaps[kh];
              if (colorMaps) {
                const sizeMaps = colorMaps[color];
                if (sizeMaps) {
                  sizes.forEach((size) => {
                    if (obj[size]) {
                      const sizelist = sizeMaps[size];
                      if (sizelist) {
                        const cloth = sizelist[0];
                        addArr.push({
                          e_clothes_id: cloth.id,
                          occur_date: this.$utils.excelDate(occurDate, 'yyyy-MM-dd'),
                          code: cloth.code,
                          kh,
                          color,
                          size,
                          num: obj[size],
                        });
                      } else {
                        errs.push({ row, kh, color, size });
                      }
                    }
                  });
                } else {
                  errs.push({ row, kh, color });
                }
              } else {
                errs.push({ row, kh });
              }
            }
          }
        });
        this.uploadSizes = sizes;
        this.addArr = addArr;
        this.uploadDialog = true;
        if (errs) {
          this.notFindList = errs;
        }
      };
      fileReader.readAsBinaryString(this.files[0]);
    },
    cancelUpload() {
      this.uploadDialog = false;
      this.addArr = [];
      this.$refs.file.reset();
    },
    reset() {
      this.$refs.form.reset();
    },
    save() {
      const did = this.uploadDistributor.id;
      if (!did) {
        this.$message({
          type: 'warning',
          message: '请在第一行填入正确的分销商名称',
          duration: 5000,
          showClose: true,
        });
      }
      const obj = {
        distributor_id: did,
        list: this.addArr,
      };
      this.axios.post('distribute/sell', obj).then(() => {

      });
    },
  },
};
</script>

<style>
</style>
