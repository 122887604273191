<template>
  <el-card>
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane name="first" label="订单情况">
        <!-- table -->
        <div>
          <el-button class="pull-right" type="primary" @click="getTj">刷新</el-button>
          <span class="text-warning"> 显示数据说明：已入库数量 / 订单数  (待入库数量) </span>
          <div class="box-inline standard-search pull-right">
            <el-input placeholder="款号" v-model="queryKh" clearable></el-input>
            <!-- <el-input placeholder="编号" v-model="queryForm.code"></el-input> -->
          </div>
          <table class="table bordered">
            <thead>
              <tr>
                <th>款号</th>
                <th>编号</th>
                <th>图片</th>
                <th>颜色</th>
                <th v-for="size in sizes" :key= size>{{size}}</th>
                <th>合计</th>
              </tr>
            </thead>
            <tbody>
              <template v-for="(mkh) in ftjData">
                <tr v-for="(item, index) in mkh.list" :key="`${mkh.kh}_${index}`">
                  <td :rowspan="mkh.list.length" v-if="index=== 0">
                    {{mkh.kh}}<br/>
                    {{mkh.in}} / {{mkh.po}}
                    <span v-if="mkh.po - mkh.in > 0" class="text-danger">({{ mkh.po - mkh.in}})</span>

                  </td>
                  <td>{{item.code.substr(0, 8)}}</td>
                  <td class="imgtd">
                    <img v-if="item.simg" style="height: 60px; display: block;" :src="'data:image/jpg/png/gif;base64,' + item.simg" alt=""/>
                  </td>
                  <td>{{item.color}}</td>
                  <td v-for="size in sizes" :key= size>
                    <span v-if="item[size].po">
                      {{item[size].in}} / {{item[size].po}}
                      <span v-if="item[size].po - item[size].in > 0" class="text-danger"> ({{item[size].po - item[size].in}}) </span>
                    </span>
                  </td>
                  <td>
                    {{item.in}} / {{item.po}}
                    <span v-if="item.po - item.in > 0" class="text-danger">
                      ({{item.po-item.in}})
                    </span>
                  </td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
      </el-tab-pane>
      <el-tab-pane name="second" label="订单上传">
        <div class="clearfix">
          <!-- <el-button type="primary" @click="toAdd">新增</el-button> -->
          <div class="mar-btm-10 pull-right">
            <div class="box-inline standard-search">
              <el-input  placeholder="订单号" v-model="queryForm.order_num"></el-input>
            </div>
            <!-- <div class="box-inline standard-search">
              <el-input  placeholder="款号" v-model="queryForm.code"></el-input>
            </div> -->
            <div class="box-inline standard-search">
              <el-select v-model="queryForm.status" clearable>
                <el-option value="1" label="进行中"></el-option>
                <el-option value="2" label="已结束"></el-option>
              </el-select>
            </div>
            <el-button size="small" @click="onSubmitQuery">
              <i class="fa fa-fw fa-search"></i>
            </el-button>
          </div>
          <file-uploader ref="file"
            accept=".xlsx"
            btnName="上传excel"
            :multiple="false"
            @change="fileChange"
            :showPreview="false"
            :showFileHint="false">
          </file-uploader>
        </div>
        <div>
          <table class="table bordered">
            <thead>
              <tr>
                <th>订单号</th>
                <th>订单日期</th>
                <th>交期</th>
                <!-- <th>款号</th>
                <th>颜色</th>
                <th>尺码</th> -->
                <th>已入库</th>
                <th>总件数</th>
                <th>状态</th>
                <th>操作</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in list" :key="item.id">
                <td>{{item.order_num}}</td>
                <td>{{item.order_date}}</td>
                <td>{{item.deadline}}</td>
                <!-- <td>{{item.code ? item.code.substr(0, 5) : ''}}</td>
                <td>{{item.color}}</td>
                <td>{{item.size}}</td> -->
                <td>{{item.total_in}}</td>
                <td>{{item.total}}</td>
                <td>{{item.status_text}}</td>
                <td>
                  <el-button type="warning" icon="el-icon-edit" @click="toEdit(item)"></el-button>
                  <!-- <el-button type="danger" icon="el-icon-delete" @click="delIt(item)"></el-button> -->
                </td>
              </tr>
            </tbody>
          </table>
          <div class="clearfix mar-top">
            <el-pagination class="pull-right"
              @current-change="changePage"
              layout="total, prev, pager, next"
              :total="totalItem"
              :current-page="currentPage"
              :page-size="pageSize">
            </el-pagination>
          </div>
        </div>
      </el-tab-pane>
    </el-tabs>

    <el-dialog :visible.sync="uploadDialog" title="上传订单" :close-on-click-modal="false" @close="cancelUpload">
      <div>
        <span v-if="notFindList.length > 0">没有对应的订单</span>
        <div v-for="(item, index) in notFindList" :Key="index">
          <span>第{{item.row}}条数据</span>
          <span>{{item.kh}}</span>
          <span>{{item.color}}</span>
          <span>{{item.size}}</span>
        </div>
      </div>
      <table class="table bordered">
        <thead>
          <tr>
            <th>#</th>
            <th>编号</th>
            <th>订单号</th>
            <th>款号</th>
            <th>颜色</th>
            <th>下单日期</th>
            <th>交期</th>
            <th v-for="item in uploadSizes" :key="item">{{item}}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item , index) in uploadList" :key="index">
            <td>{{index + 1}}</td>
            <td>{{item.code.substr(0, 8)}}</td>
            <td>{{item.order_num}}</td>
            <td>{{item.kh}}</td>
            <td>{{item.color}}</td>
            <td>{{item.order_date}}</td>
            <td>{{item.deadline}}</td>
            <td v-for="size in uploadSizes" :key="size">{{item[size]}}</td>
          </tr>
        </tbody>
      </table>
      <el-form :model="uploadObj" label-width="100px">
        <!-- <el-form-item label="订单日期">
          <el-date-picker v-model="uploadObj.order_date" value-format="yyyy-MM-dd"></el-date-picker>
        </el-form-item>
        <el-form-item label="交期">
          <el-date-picker v-model="uploadObj.deadline" value-format="yyyy-MM-dd"></el-date-picker>
        </el-form-item> -->
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="cancelUpload" icon="fa fa-fw fa-times"> 取 消</el-button>
        <el-button type="primary" @click="save" :disabled="waiting" icon="fa fa-fw fa-floppy-o"> 保存</el-button>
      </div>
    </el-dialog>

    <el-dialog :visible.sync="addDialog" title="新订单" :close-on-click-modal="false">
      <table class="table bordered">
        <thead>
          <tr>
            <th>#</th>
            <th>编号</th>
            <th>款号</th>
            <th>颜色</th>
            <th>尺码</th>
            <th>数量</th>
            <th>操作</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item , index) in addNewArr" :key="index">
            <td>{{index + 1}}</td>
            <td>{{item.code}}</td>
            <td>{{item.kh}}</td>
            <td>{{item.color}}</td>
            <td>{{item.size}}</td>
            <td><el-input v-model="item.total"></el-input></td>
            <td><el-button type="danger" icon="el-icon-delete" @click="delNewItem(index)"></el-button></td>
          </tr>
          <tr>
            <td colspan="7">
              <label>新增款号：</label>
              <el-autocomplete
                v-model="addItemCode"
                :fetch-suggestions="querySearchAsync"
                placeholder="请输入内容"
                @select="handleSelect">
                <template slot-scope="{ item }">
                  <span class="name">{{ item.code }}</span>
                </template>
              </el-autocomplete>
            </td>
          </tr>
        </tbody>
      </table>
      <el-form :model="newObj" label-width="100px">
        <el-row>
          <el-col :span=12>
            <el-form-item label="订单日期">
              <el-date-picker v-model="newObj.order_date" value-format="yyyy-MM-dd"></el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span=12>
            <el-form-item label="交期">
              <el-date-picker v-model="newObj.deadline" value-format="yyyy-MM-dd"></el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="addDialog = false" icon="fa fa-fw fa-times"> 取 消</el-button>
        <el-button type="primary" @click="saveNew" :disabled="waiting" icon="fa fa-fw fa-floppy-o"> 保存</el-button>
      </div>
    </el-dialog>

    <el-dialog :visible.sync="editDialog" title="订单" :close-on-click-modal="false">
      <table class="table bordered">
        <thead>
          <tr>
            <th>#</th>
            <th>编号</th>
            <th>款号</th>
            <th>颜色</th>
            <th>尺码</th>
            <th>数量</th>
            <th>操作</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item , index) in addNewArr" :key="index">
            <td>{{index + 1}}</td>
            <td>{{item.code}}</td>
            <td>{{item.kh}}</td>
            <td>{{item.color}}</td>
            <td>{{item.size}}</td>
            <td>
              {{item.total_in}}/{{item.orgTotal}}
              <el-input v-model="item.total" style="width: 100px;"></el-input>
              <el-button icon="el-icon-check" :disabled="waiting" type="primary" @click="updateItem(item)"></el-button>
            </td>
            <td><el-button type="danger" :disabled="waiting" icon="el-icon-delete" @click="delEditItem(item, index)"></el-button></td>
          </tr>
          <!-- <tr>
            <td colspan="7">
              <label>新增款号：</label>
              <el-autocomplete
                v-model="addItemCode"
                :fetch-suggestions="querySearchAsync"
                placeholder="请输入内容"
                @select="handleSelect">
                <template slot-scope="{ item }">
                  <span class="name">{{ item.code }}</span>
                </template>
              </el-autocomplete>
            </td>
          </tr> -->
        </tbody>
      </table>
      <el-form model="editObj" label-width="100px">
        <el-row>
          <el-col :span=12>
            <el-form-item label="订单日期">
              <el-date-picker v-model="editObj.order_date" value-format="yyyy-MM-dd"></el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span=12>
            <el-form-item label="交期">
              <el-date-picker v-model="editObj.deadline" value-format="yyyy-MM-dd"></el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="editDialog = false" icon="fa fa-fw fa-times"> 取 消</el-button>
        <!-- <el-button type="primary" @click="saveNew" :disabled="waiting" icon="fa fa-fw fa-floppy-o"> 保存</el-button> -->
      </div>
    </el-dialog>
  </el-card>
</template>

<script>
import * as xlsx from 'xlsx';
import FileUploader from '@/components/FileUpload';

export default {
  components: {
    FileUploader,
  },
  data() {
    return {
      loading: false,
      waiting: false,
      activeName: 'first',
      sizes: [],
      allSize: ['F', 'XS', 'S', 'S/M', 'M', 'M/L', 'L', 'XL', 'XXL'],
      addDialog: false,
      queryKh: null,
      queryForm: {
        status: null,
        order_num: null,
        code: null,
      },
      addNewArr: [],
      list: [],
      files: [],
      khMaps: null,
      addArr: [],
      uploadSizes: [],
      uploadDialog: false,
      uploadObj: {
        order_date: this.$utils.FormatDate(),
        deadline: null,
      },
      newObj: {
        order_date: this.$utils.FormatDate(),
        deadline: null,
      },
      notFindList: [],
      poNum: null,
      addItemCode: '',
      editDialog: false,
      editObj: {},
      // tj
      tjData: {},
      // 分页pagination数据
      totalItem: 0,
      currentPage: 1,
      pageSize: 15,
    };
  },
  computed: {
    uploadList() {
      const r = [];
      this.addArr.forEach((item) => {
        let lastOne = null;
        if (r.length > 0) {
          lastOne = r[r.length - 1];
        }
        if (lastOne && lastOne.code.substr(0, 8) === item.code.substr(0, 8)) {
          lastOne[item.size] = item.total;
          this.$set(lastOne, item.size, item.total);
        } else {
          const obj = {
            code: item.code,
            order_num: item.order_num,
            kh: item.kh,
            color: item.color,
            order_date: item.order_date,
            deadline: item.deadline,
          };
          obj[item.size] = item.total;
          r.push(obj);
        }
      });
      return r;
    },
    newExistMap() {
      const map = {};
      this.addNewArr.forEach((i) => {
        map[i.code.substr(0, 8)] = 1;
      });
      return map;
    },
    ftjData() {
      const r = [];
      const khs = Object.keys(this.tjData);
      khs.forEach((kh) => {
        const slst = this.tjData[kh];
        let tin = 0;
        let tpo = 0;
        slst.forEach((item) => {
          let poNum = 0;
          let inNum = 0;
          this.sizes.forEach((size) => {
            if (item[size] && item[size].po) {
              poNum += item[size].po * 1;
              inNum += item[size].in * 1;
            }
          });
          item.po = poNum;
          item.in = inNum;
          tin += inNum;
          tpo += poNum;
          if (item.imgs) {
            item.simg = item.imgs.simg;
          }
        });
        if (this.queryKh) {
          if (kh.indexOf(this.queryKh) !== -1) {
            r.push({
              kh,
              in: tin,
              po: tpo,
              list: slst,
            });
          }
        } else {
          r.push({
            kh,
            in: tin,
            po: tpo,
            list: slst,
          });
        }
      });
      return r;
    },
  },
  created() {
    this.getTj();
    this.getSKUs();
    this.onSubmitQuery();
  },
  methods: {
    handleClick() {},
    getSKUs(kh) {
      const params = {};
      if (kh) {
        params.kh = kh;
      }
      this.axios.get('allkhclothes', { params }).then((r) => {
        const list = r.data;
        this.khMaps = list;
      });
    },
    fileChange(files) {
      if (files.length > 0) {
        this.files = [];
        Array.prototype.slice.call(files).forEach((file) => {
          this.files.push(file);
        });
      } else {
        this.files = [];
      }
      if (!this.khMaps) {
        setTimeout(() => {
          this.this.fileChange(files);
        }, 3000);
      } else {
        this.toAddPo();
      }
    },
    getTj() {
      this.axios.get('tj').then((r) => {
        this.tjData = r.data.list;
        this.sizes = r.data.sizes;
      });
    },
    toAddPo() {
      this.poNum = null;
      const fileReader = new FileReader();
      fileReader.onload = (ev) => {
        const data = ev.target.result;
        const wb = xlsx.read(data, { type: 'binary' });
        const sheetName = wb.SheetNames[0];
        const list = xlsx.utils.sheet_to_json(wb.Sheets[sheetName], { range: 0, header: 'A' });
        let row = 0;
        const errs = [];
        const addArr = [];
        // const allkh = {};
        let sizeRow = null;
        const colMap = {};
        const sizes = [];
        if (!this.khMaps) {
          setTimeout(() => {
            this.toAddPo();
          }, 5000);
          return;
        }
        list.forEach((item) => {
          row++;
          const keys = Object.keys(item);
          if (row < 3) {
            keys.forEach((col) => {
              const itemVal = item[col];
              // if (sizeRow && sizeRow === row) {
              //   if (this.allSize.indexOf(itemVal) > 1) {
              //     colMap[itemVal] = col;
              //     sizes.push(itemVal);
              //   }
              // }
              if (this.allSize.indexOf(itemVal) > 1) {
                colMap[itemVal] = col;
                sizeRow = row;
                sizes.push(itemVal);
              }
              if (itemVal === '下单日期') {
                colMap.order_date = col;
              }
              if (itemVal === '交期') {
                colMap.deadline = col;
              }
              if (itemVal === '款号') {
                colMap.kh = col;
              }
              if (itemVal === '订单号') {
                colMap.poNum = col;
              }
              if (itemVal === '颜色') {
                colMap.color = col;
              }
              if (!colMap.sizeCol && String(itemVal).indexOf('码') !== -1) {
                colMap.sizeCol = col;
                sizeRow = row + 1;
              }
            });
            if (row === sizeRow + 1) {
              this.poNum = item[colMap.poNum];
            }
          }
          // 尺码后的行
          if (sizeRow && row > sizeRow) {
            if (item[colMap.kh]) {
              const poNum = item[colMap.poNum];
              this.poNum = poNum;
              let kh = null;
              if (colMap.kh) {
                kh = item[colMap.kh];
              } else {
                kh = poNum.substr(0, 5);
              }
              let orderDate = null;
              if (colMap.order_date) {
                orderDate = item[colMap.order_date];
              }
              let deadline = null;
              if (colMap.deadline) {
                deadline = item[colMap.deadline];
              }
              const color = item[colMap.color];
              if (kh && color) {
                const obj = {
                  kh,
                  color,
                  deadline,
                  order_num: poNum,
                  order_date: orderDate,
                };
                sizes.forEach((size) => {
                  if (item[colMap[size]]) {
                    obj[size] = item[colMap[size]];
                  }
                });
                // allkh[kh] = 1;
                const colorMaps = this.khMaps[kh];
                if (colorMaps) {
                  const sizeMaps = colorMaps[color];
                  if (sizeMaps) {
                    sizes.forEach((size) => {
                      if (obj[size]) {
                        const sizelist = sizeMaps[size];
                        if (sizelist) {
                          const cloth = sizelist[0];
                          addArr.push({
                            e_clothes_id: cloth.id,
                            order_num: poNum,
                            order_date: this.$utils.excelDate(orderDate, 'yyyy-MM-dd'),
                            code: cloth.code,
                            kh,
                            deadline: this.$utils.excelDate(deadline, 'yyyy-MM-dd'),
                            color,
                            size,
                            total: obj[size],
                          });
                        } else {
                          errs.push({ row, kh, color, size });
                        }
                      }
                    });
                  } else {
                    errs.push({ row, kh, color });
                  }
                } else {
                  errs.push({ row, kh });
                }
              }
            }
          }
        });
        this.uploadSizes = sizes;
        this.addArr = addArr;
        this.uploadDialog = true;
        if (errs) {
          this.notFindList = errs;
        }
      };
      fileReader.readAsBinaryString(this.files[0]);
    },
    cancelUpload() {
      this.uploadDialog = false;
      this.addArr = [];
      this.$refs.file.reset();
    },
    onSubmitQuery() {
      this.queryParams = { ...this.queryForm };
      this.changePage(1);
    },
    // 翻页
    changePage(page) {
      this.currentPage = page;
      this.getList();
    },
    // 打开新增
    toAdd() {
      this.addNewArr = [];
      this.addDialog = true;
    },
    getList() {
      this.loading = true;
      const params = {
        ...this.queryParams,
        page: this.currentPage,
        pageSize: this.pageSize,
      };
      this.axios.get('e-clothes-po', { params }).then((r) => {
        this.loading = false;
        this.list = r.data.data;
        this.totalItem = r.data.total;
      }, (e) => {
        this.loading = false;
        this.$utils.resp(e);
      });
    },
    save() {
      const obj = {
        ...this.uploadObj,
        list: this.addArr,
      };
      this.waiting = true;
      this.axios.post('e-clothes-po', obj).then(() => {
        this.waiting = false;
        this.getList();
        this.uploadDialog = false;
      }, (e) => {
        this.waiting = false;
        this.$utils.resp(e);
      });
    },
    querySearchAsync(q, cb) {
      if (q) {
        const params = {
          code: q,
          page: 1,
          pageSize: 30,
        };
        this.$http.get('e-clothes', { params }).then((r) => {
          cb(r.data.data);
        });
      } else {
        cb([]);
      }
    },
    handleSelect(obj) {
      if (obj.code) {
        const colorCode = obj.code.substr(0, 8);
        if (this.newExistMap[colorCode]) {
          this.$confirm('已经有该款号颜色，是否覆盖?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
          }).then(() => {
            this.addNewKhs(obj);
          }, () => {});
        } else {
          this.addNewKhs(obj);
        }
      }
    },
    addNewKhs(obj) {
      const colorCode = obj.code.substr(0, 8);
      const params = {
        code: colorCode,
        page: 1,
        pageSize: 30,
      };
      this.addNewArr = this.addNewArr.filter((i) => i.code.substr(0, 8) !== colorCode);
      this.addItemCode = obj.code.substr(0, 8);
      this.$http.get('e-clothes', { params }).then((r) => {
        const l = r.data.data;
        this.allSize.forEach((size) => {
          l.forEach((i) => {
            if (i.size === size) {
              this.addNewArr.push({
                e_clothes_id: i.id,
                code: i.code,
                kh: i.code.substr(0, 5),
                color: i.color,
                size: i.size,
                total: 0,
              });
            }
          });
        });
      });
    },
    delNewItem(index) {
      this.addNewArr.splice(index, 1);
    },
    saveNew() {
      const obj = {
        ...this.newObj,
        list: this.addNewArr,
      };
      this.waiting = true;
      this.axios.post('e-clothes-po', obj).then(() => {
        this.waiting = false;
        this.getList();
        this.addDialog = false;
      }, (e) => {
        this.waiting = false;
        this.$utils.resp(e);
      });
    },
    toEdit(obj) {
      this.axios.get(`e-clothes-po/${obj.id}`).then((r) => {
        this.editDialog = true;
        const itemList = r.data.items;
        itemList.forEach((i) => {
          i.orgTotal = i.total;
        });
        this.addNewArr = itemList;
        this.editObj = r.data;
      }, (e) => {
        this.$utils.resp(e);
      });
    },
    saveUpdate() {

    },
    updateItem(item) {
      this.waiting = true;
      this.axios.put(`e-clothes-po-item/${item.id}`, item).then(() => {
        this.waiting = false;
        item.orgTotal = item.total;
        this.getList();
      }, (e) => {
        this.waiting = false;
        this.$utils.resp(e);
      });
    },
    delEditItem(item, index) {
      this.$confirm('确认删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        this.waiting = true;
        this.axios.delete(`e-clothes-po-item/${item.id}`).then(() => {
          this.waiting = false;
          this.getList();
          this.addNewArr.splice(index, 1);
        }, (e) => {
          this.waiting = false;
          this.$utils.resp(e);
        });
      }, () => {});
    },
    delIt(item) {
      this.$confirm('确认删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        this.axios.delete(`e-clothes-po/${item.id}`).then(() => {
          this.getList();
        }, (e) => {
          this.$utils.resp(e);
        });
      }, () => {});
    },

  },
};
</script>

<style lang="scss">
.table td.imgtd {
  padding: 0 !important;
}

</style>
